import React from 'react';
import styled from 'styled-components';
import backImage from '../assets/bg_img_side.jpg';
import sideImage from '../assets/flora_logo_plus.png';
import useIntersectionObserver from '../components/Hook';


const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  min-height: 100vh;
  position: relative;
  font-family: 'Poppins', Arial, sans-serif;
  flex-direction: row; /* Default layout with image on the left */
  
  @media (max-width: 768px) {
      padding-top: 100px;
    padding-bottom: 100px;
    flex-direction: column; /* Stack image on top when screen is smaller */
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -2;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: opacity 1s ease-in-out;
`;

const TextOverlay = styled.div`
  color: #F0F0F5;
  font-family: 'Poppins-SemiBold', Arial, sans-serif;
  font-size: 42px;
  padding: 20px 15% 20px 100px;
  text-align: left;
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: opacity 1s ease-in-out;

  @media (max-width: 1256px) {
    font-size: 28px;
    padding: 20px 30px 20px 40px;
  }

  @media (max-width: 768px) {
    font-size: 24px;
    padding-left: 40px;
    margin-right: 40px;
    padding-right: 0;
  }
`;

const SubtitleText = styled.div`
  color: #F0F0F5;
  font-family: 'Poppins', Arial, sans-serif;
  font-weight: bold;
  font-size: 32px;
  line-height: 35px;
  padding: 10px 20px 20px 100px;
  text-align: left;
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: opacity 1s ease-in-out;

  @media (max-width: 768px) {
    font-size: 20px;
    padding: 0;
    margin-top: -40px;
  }
`;

const AdditionalText = styled.div`
  color: #F0F0F5;
  font-family: 'Poppins', Arial, sans-serif;
  font-size: 24px;
  padding: 0 25% 20px 100px;
  text-align: left;
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: opacity 1s ease-in-out;

  @media (max-width: 1256px) {
    font-size: 18px;
    padding: 20px 30px 20px 40px;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    padding-left: 40px;
    margin-right: 40px;
    padding-right: 0;
  }
`;

const ColoredText = styled.span`
  color: #94FFD9FF;
`;

const SideImage = styled.img`
  width: 325px;
  height: auto;
  padding-left: 100px;
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: opacity 1s ease-in-out;

  @media (max-width: 768px) {
    padding-left: 0;
    padding-top: 125px;
      padding-bottom: 50px;
      margin-right: 0;
  }
`;

const GradientOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgb(10, 20, 61), rgba(10, 20, 61, 0.5), rgba(0, 0, 0, 0));
    z-index: -1; // Ensure this is above the background image
`;

const LearnMoreButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  min-width: 150px;
  margin-top: 20px;
    margin-left: 100px;
  border: 2px solid ${props => props.color};
  color: ${props => props.color};
  background: none;
  cursor: pointer;
  font-family: 'Poppins', Arial, sans-serif;
  text-transform: uppercase;
  font-size: 14px;
    
  @media (max-width: 1256px) {
    margin-left: 40px;
  }

  &::after {
    content: ' ➔';
    display: inline-block;
    margin-left: 5px;
  }

  &:hover {
    background-color: ${props => props.color};
    color: #0A143D;
  }
`;

const handleMailTo = (prompt) => {
  const emailAddress = 'dev@databiomes.com';
  const subject = encodeURIComponent(`Databiomes Information Request: ${prompt}`);
  const body = encodeURIComponent('Company Name: \nContact Name: \nPosition: \n---------\nProvide optional details here:\n');
  const mailtoLink = `mailto:${emailAddress}?subject=${subject}&body=${body}`;

  window.location.href = mailtoLink;
};

const LearnMore = ({ prompt, color }) => (
  <LearnMoreButton
    color={color}
    onClick={() => handleMailTo(prompt)}
  >
    Learn More
  </LearnMoreButton>
);

const MainContent = () => {
    const [isVisible, elementRef] = useIntersectionObserver({
    threshold: 0.1, // Adjust the threshold as needed
  });

  return (
  <MainContainer id={"AboutID"}>
    <BackgroundImage src={backImage} alt="Background" />
      <GradientOverlay />
      <TextContainer ref={elementRef} isVisible={isVisible}>
      <SideImage src={sideImage} alt="Side" ref={elementRef} isVisible={isVisible} />
      <SubtitleText ref={elementRef} isVisible={isVisible}>
        Powering Future AI Agents
    </SubtitleText>
      </TextContainer>
    <TextContainer ref={elementRef} isVisible={isVisible}>
    <TextOverlay ref={elementRef} isVisible={isVisible}>
      With our data biome inference engine, we can deploy hundreds of our flora models specialized on hundreds of tasks. All running locally and in parallel. <ColoredText>This creates flora+</ColoredText>
        {/*With our IP, you can create hundreds of our Flora 'nano' language models, specialized on hundreds of tasks, all running in parallel, locally on almost any device. <ColoredText>This creates flora+</ColoredText>*/}
    </TextOverlay>
    <AdditionalText ref={elementRef} isVisible={isVisible}>
        A future of AI agents powered by Flora+ is a future we are excited by. It drives us to make our models even more efficient and powerful.
    </AdditionalText>
    <LearnMore prompt={"Flora+"} color={"#94FFD9"} />
    </TextContainer>
  </MainContainer>
);};

export default MainContent;

