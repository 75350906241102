import React from 'react';
import styled from 'styled-components';
import backImage from '../assets/bg_blue_outline.png';
import sideImage from '../assets/db_light_green_mark.png';
import useIntersectionObserver from '../components/Hook';


const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  min-height: 100vh;
  position: relative;
  font-family: 'Poppins', Arial, sans-serif;
  flex-direction: row; /* Default layout with image on the left */
  
  @media (max-width: 768px) {
      padding-top: 100px;
    padding-bottom: 100px;
    flex-direction: column; /* Stack image on top when screen is smaller */
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: opacity 1s ease-in-out;
`;

const TextOverlay = styled.div`
  color: #F0F0F5;
  font-family: 'Poppins-SemiBold', Arial, sans-serif;
  font-size: 48px;
  padding: 20px 15% 20px 100px;
  text-align: left;
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: opacity 1s ease-in-out;

  @media (max-width: 1256px) {
    font-size: 32px;
    padding: 20px 30px 20px 40px;
  }

  @media (max-width: 768px) {
    font-size: 24px;
    padding-left: 40px;
    margin-right: 40px;
    padding-right: 0;
  }
`;

const AdditionalText = styled.div`
  color: #F0F0F5;
  font-family: 'Poppins', Arial, sans-serif;
  font-size: 24px;
  padding: 0 25% 20px 100px;
  text-align: left;
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: opacity 1s ease-in-out;

  @media (max-width: 1256px) {
    font-size: 18px;
    padding: 20px 30px 20px 40px;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    padding-left: 40px;
    margin-right: 40px;
    padding-right: 0;
  }
`;

const ColoredText = styled.span`
  color: #94FFD9FF;
`;

const SideImage = styled.img`
  width: 300px;
  height: auto;
  padding-left: 100px;
  margin-right: 20px;
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: opacity 1s ease-in-out;

  @media (max-width: 768px) {
    padding-left: 0;
    padding-top: 125px;
      padding-bottom: 50px;
      margin-right: 0;
  }
`;

const MainContent = () => {
    const [isVisible, elementRef] = useIntersectionObserver({
    threshold: 0.1, // Adjust the threshold as needed
  });

  return (
  <MainContainer id={"AboutID"}>
    <BackgroundImage src={backImage} alt="Background" />
    <SideImage src={sideImage} alt="Side" ref={elementRef} isVisible={isVisible} />
    <TextContainer ref={elementRef} isVisible={isVisible}>
    <TextOverlay ref={elementRef} isVisible={isVisible}>
        Leveraging intelligent data, we are developing ultra-accurate, highly-optimized ‘nano’ language models that create <ColoredText>AI agents of the future</ColoredText>
    </TextOverlay>
    <AdditionalText ref={elementRef} isVisible={isVisible}>
        "What we do is not ‘magic’. It is the sum of highly analytical and deliberate steps, the science that delivers against our creative vision."
    </AdditionalText>
    </TextContainer>
  </MainContainer>
);};

export default MainContent;

