import React from 'react';
import styled from 'styled-components';
import useIntersectionObserver from "./Hook";
import backImage from '../assets/bg_blue_filled.png';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  min-height: 100vh;
  position: relative;
  font-family: 'Poppins', Arial, sans-serif;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
    margin-top: 300px;
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: opacity 1s ease-in-out;
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 100px;
    padding-right: 50px;
  max-width: 45%;
  
  @media (max-width: 768px) {
    max-width: 100%;
    padding-left: 50px;
  }
`;

const TextBold = styled.div`
  color: #F0F0F5;
  font-family: 'Poppins', Arial, sans-serif;
  font-weight: bold;
  font-size: 36px;
  @media (max-width: 875px) {
        font-size: 30px;
    }
    @media (max-width: 768px) {
    font-size: 28px;
    }
`;

const SingleLine = styled.div`
  background-color: #F0F0F5;
  width: 200px;
  height: 5px;
  margin-top: 25px;
  margin-left: -20px;
  @media (max-width: 768px) {
        width: 5px;
        height: 100px;
        margin: 0;
    }
`;

const TextRegular = styled.div`
  max-width: 300px;
  color: #F0F0F5;
  font-family: 'Poppins', Arial, sans-serif;
  font-size: 36px;
  @media (max-width: 875px) {
        font-size: 30px;
    }
    @media (max-width: 768px) {
        margin-top: 50px;
      max-width: 200px;
    text-align: center;
    }
`;

const Separator = styled.div`
    width: 100px;
    height: auto;
    border-top: 5px solid #F0F0F5;
    border-bottom: 5px solid #F0F0F5;
    border-right: 5px solid #F0F0F5;
    margin: 0 20px;
    align-self: stretch;

    @media (max-width: 768px) {
        width: auto;
        height: 100px;
        margin-top: -85px;
        border-top: 0;
        padding-left: 20px;
        padding-right: 20px;
        border-left: 5px solid #F0F0F5;
        border-right: 5px solid #F0F0F5;
    }
`;

const AdditionalText = styled.div`
  color: #F0F0F5;
  font-family: 'Poppins', Arial, sans-serif;
  font-size: 24px;
  padding: 100px 25% 100px 100px;
  text-align: left;

  @media (max-width: 1256px) {
    font-size: 24px;
  }

  @media (max-width: 768px) {
    font-size: 18px;
    padding-left: 40px;
    margin-right: 40px;
    padding-right: 0;
    margin-top: -50px;
    text-align: center;
  }
`;

const ColoredText = styled.span`
  color: #94FFD9FF;
`;

const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`;

const MainContent = () => {
    const [isVisible, elementRef] = useIntersectionObserver({
    threshold: 0.1, // Adjust the threshold as needed
  });
  return (
    <MainContainer id={"UseCaseID"}>
      <BackgroundImage src={backImage} alt="Background" />
      <ContentWrapper ref={elementRef} isVisible={isVisible}>
        <Column>
          <TextBold>IoT & Consumer Electronics.</TextBold>
          <TextBold>Mobile Apps & Gaming.</TextBold>
          <TextBold>Robotics & Manufacturing.</TextBold>
        </Column>
        <Separator />
        <SingleLine />
        <Column>
          <TextRegular>Environments with memory constraints.</TextRegular>
        </Column>
      </ContentWrapper>
        <AdditionalText>These environments with minimal hardware memory available, require ultra-efficient language models without compromising accuracy. With Flora, if it can be imagined in these environments, <ColoredText>it can be created.</ColoredText></AdditionalText>
    </MainContainer>
  );
};

export default MainContent;

