import React, { useState } from 'react';
import styled from 'styled-components';
import logo from '../assets/top_logo.png';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 50px;
  margin: 50px;
  border-bottom: 1px solid white;
  position: absolute;
  width: calc(100% - 100px);
  top: 0;
  left: 0;
  font-family: 'Poppins', Arial, sans-serif;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    padding: 10px 20px;
    margin: 20px;
    width: calc(100% - 40px);
  }
`;

const Logo = styled.img`
  height: 100%;
  max-height: 30px;
  padding-bottom: 5px;

  @media (max-width: 768px) {
    max-height: 30px;
  }
`;

const Nav = styled.nav`
    display: flex;
    gap: 15px;

    @media (max-width: 768px) {
        display: ${props => (props.isOpen ? 'flex' : 'none')};
        flex-direction: column;
        background: rgb(148, 255, 217);
        position: absolute;
        top: 70px;
        right: 0;
        width: 200px;
        padding: 10px;
        border-radius: 5px;
    }
`;

const NavItem = styled.a`
    text-decoration: none;
    color: #F0F0F5;
    font-size: 14px;
    font-family: 'Poppins', Arial, sans-serif;
    padding-left: 15px;
    border-left: 1px solid #F0F0F5;

    &:first-child {
        border-left: none;
    }

    &:hover {
        color: #94FFD9FF;
    }

    @media (max-width: 768px) {
        color: #0A143D;
        padding-bottom: 10px;
        padding-top: 10px;
        border-left: none;
        border-bottom: 1px solid #0A143D;
        
        &:hover {
            color: #0A143D;
            opacity: 0.5;
        }

        &:last-child {
            border-bottom: none;
        }
    }
`;

const Hamburger = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;

  @media (max-width: 768px) {
    display: flex;
  }

  div {
    width: 25px;
    height: 3px;
    background: #F0F0F5;
    margin: 4px 0;
  }
`;

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  const handleNavClick = (event, sectionId) => {
    event.preventDefault();
    const section = document.querySelector(sectionId);
    section.scrollIntoView({ behavior: 'smooth' });
    setIsOpen(false); // Close the nav menu on click
  };

  return (
    <HeaderContainer>
      <Logo src={logo} alt="Logo" />
      <Hamburger onClick={toggleNav}>
        <div></div>
        <div></div>
        <div></div>
      </Hamburger>
      <Nav isOpen={isOpen}>
        <NavItem href="#about" onClick={(e) => handleNavClick(e, '#AboutID')}>WHAT WE DO</NavItem>
        <NavItem href="#services" onClick={(e) => handleNavClick(e, '#ServiceID')}>SERVICES</NavItem>
        <NavItem href="#usecases" onClick={(e) => handleNavClick(e, '#UseCaseID')}>USE CASE</NavItem>
        {/*<NavItem href="#contact" onClick={(e) => handleNavClick(e, '#contact')}>ABOUT US</NavItem>*/}
      </Nav>
    </HeaderContainer>
  );
};

export default Header;



