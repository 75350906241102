import { createGlobalStyle } from 'styled-components';

import PoppinsRegular from './assets/Poppins/Poppins-Regular.ttf';
import PoppinsBold from './assets/Poppins/Poppins-Bold.ttf';
import PoppinsItalic from './assets/Poppins/Poppins-Italic.ttf';
import PoppinsLight from './assets/Poppins/Poppins-Light.ttf';
import PoppinsSemiBold from './assets/Poppins/Poppins-SemiBold.ttf';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Poppins';
    src: url(${PoppinsRegular}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Poppins-Light';
    src: url(${PoppinsLight}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Poppins-SemiBold';
    src: url(${PoppinsSemiBold}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: url(${PoppinsBold}) format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: url(${PoppinsItalic}) format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'Poppins', Arial, sans-serif;
    background: none;
  }

  .App {
    position: relative;
    height: 100%;
  }
`;

export default GlobalStyle;

