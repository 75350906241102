import React from 'react';
import styled from 'styled-components';
import sampleVideo from '../assets/logo.mp4';
import useIntersectionObserver from '../components/Hook';

const MainContainer = styled.div`
    display: flex;
    align-items: center;
    background-color: #0A143D;
    justify-content: flex-start;
    height: 100vh;
    position: relative;
    font-family: 'Poppins', Arial, sans-serif;
`;

const VideoBackground = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 1.0;
`;

const MainContent = () => {
    const [isVisible, elementRef] = useIntersectionObserver({
    threshold: 0.1, // Adjust the threshold as needed
  });
    return (
  <MainContainer>
    <VideoBackground autoPlay loop muted playsInline>
      <source src={sampleVideo} type="video/mp4" />
    </VideoBackground>
  </MainContainer>
);};

export default MainContent;
