import React, { useState } from 'react';
import styled from 'styled-components';
import backImage from '../assets/bg_blue_outline.png';
import sideImage from '../assets/db_light_green_mark.png';

const MainContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
    position: relative;
    font-family: 'Poppins', Arial, sans-serif;
    padding: 30px 30px 30px 100px;

    @media (max-width: 768px) {
        padding-top: 100px;
        padding-left: 30px;
        padding-bottom: 100px;
    }
`;

const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`;

const Title = styled.h1`
  color: #F0F0F5;
  font-size: 48px;
  padding: 20px 0;
  text-align: left;

  @media (max-width: 1256px) {
    font-size: 32px;
  }

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const Description = styled.p`
  color: #F0F0F5;
  font-size: 24px;
  padding: 0 0 20px 0;
  text-align: left;

  @media (max-width: 1256px) {
    font-size: 18px;
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 0;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Image = styled.img`
  width: 300px;
  height: auto;
  margin-right: 20px;

  @media (max-width: 768px) {
    margin: 0 0 20px 0;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  padding: 20px 0;
    @media (max-width: 768px) {
    width: 70%;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  color: #F0F0F5;
  margin-bottom: 5px;
    font-family: 'Poppins', Arial, sans-serif;
  font-size: 16px;
  display: block;
`;

const Input = styled.input`
  width: 100%;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
  font-size: 16px;
    font-family: 'Poppins', Arial, sans-serif;
  transition: border-color 0.3s;

  &:focus {
    border-color: #007AFF;
    outline: none;
  }
    @media (max-width: 768px) {
    font-size: 14px;
    padding: 12px;
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
  font-size: 16px;
    font-family: 'Poppins', Arial, sans-serif;
  transition: border-color 0.3s;
  resize: none;

  &:focus {
    border-color: #007AFF;
    outline: none;
  }
    
    @media (max-width: 768px) {
    font-size: 14px;
    padding: 12px;
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  min-width: 150px;
  margin-top: 20px;
  border: 2px solid ${props => props.color};
  color: ${props => props.color};
  background: none;
  cursor: pointer;
  font-family: 'Poppins', Arial, sans-serif;
  text-transform: uppercase;
  font-size: 14px;

  &::after {
    content: ' ➔';
    display: inline-block;
    margin-left: 5px;
  }

  &:hover {
    background-color: ${props => props.color};
    color: #F0F0F5;
  }
`;

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const mailtoLink = `mailto:your-email@example.com?subject=Contact%20Form%20Submission&body=Name:%20${formData.name}%0AEmail:%20${formData.email}%0AMessage:%20${formData.message}`;
    window.location.href = mailtoLink;
  };

  return (
    <MainContainer>
      <BackgroundImage src={backImage} alt="Background" />
      <Title>Contact Us</Title>
      <Description>Please fill out the form below to get in touch with us.</Description>
      <ImageContainer>
        <Image src={sideImage} alt="Side Image 1" />
        <Image src={sideImage} alt="Side Image 2" />
      </ImageContainer>
      <FormContainer>
          <form onSubmit={handleSubmit}>
        <FormGroup>
            <Label htmlFor="name">Name</Label>
            <Input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="email">Email</Label>
            <Input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="message">Message</Label>
            <Textarea id="message" name="message" rows="4" value={formData.message} onChange={handleChange} required />
          </FormGroup>
              <Button type="submit" color={"#0A143D"}>Send</Button>
          </form>
      </FormContainer>
    </MainContainer>
  );
};

export default ContactPage;


