import React from 'react';
import styled from 'styled-components';
import sideVideo from '../assets/zoomed_bloom.mp4';
import useIntersectionObserver from "./Hook";

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
    background-color: #F0F0F5;
  height: 100%;
    min-height: 100vh;
  position: relative;
  font-family: 'Poppins', Arial, sans-serif;
  flex-direction: row; /* Default layout with image on the left */
  
  @media (max-width: 768px) {
      padding-top: 100px;
    padding-bottom: 100px;
    flex-direction: column; /* Stack image on top when screen is smaller */
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
    padding-top: 10px;
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: opacity 1s ease-in-out;
`;

const TextOverlay = styled.div`
  color: #0A143D;
  font-family: 'Poppins-SemiBold', Arial, sans-serif;
  font-size: 24px;
  padding-left: 40px;
    padding-right: 40px;
    width: 300px;
    margin-top: -140px;
  text-align: left;

  @media (max-width: 1152px) {
    font-size: 20px;
      margin-top: -20px;
      width: 200px;
    padding-left: 20px;
      padding-right: 20px;
  }

  @media (max-width: 768px) {
    font-size: 20px;
      width: 300px;
    padding-left: 40px;
      padding-bottom: 10px;
    margin-right: 40px;
      margin-top: 40px;
    padding-right: 0;
  }
`;

const TextOverlayAlt = styled.div`
  color: #0A143D;
  font-family: 'Poppins', Arial, sans-serif;
  font-size: 18px;
  padding-left: 40px;
    padding-bottom: 50px;
    padding-right: 40px;
    margin-top: -210px;
    height: 100px;
    min-width: 200px;
    max-width: 500px;
  text-align: left;

  @media (max-width: 1152px) {
    font-size: 16px;
    padding-left: 10px;
      min-width: 150px;
      margin-top: -155px;
    padding-right: 20px;
      flex-direction: column;
  }

  @media (max-width: 768px) {
    font-size: 16px;
      margin-top: 40px;
    padding-left: 40px;
    margin-right: 40px;
    padding-right: 0;
  }
`;

const AdditionalText = styled.div`
  color: #0A143D;
  font-family: 'Poppins', Arial, sans-serif;
  font-size: 16px;
  margin-top: 25px;
  margin-left: 40px;
  margin-right: 40px;
    min-height: 40px;
  text-align: left;
  border-bottom: 4px solid #0A143D;

  @media (max-width: 1152px) {
    margin-left: 20px;
    margin-right: 20px;
      min-height: 35px;
      margin-top: 15px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    margin-left: 40px;
    margin-right: 40px;
      margin-top: 10px;
  }
`;

const SideVideo = styled.video`
  width: 400px;
  height: 400px;
  object-fit: scale-down;
  border-radius: 15px;
  margin-left: 100px;
  margin-right: 20px;
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: opacity 1s ease-in-out;

  @media (max-width: 1152px) {
      width: 300px;
      height: 300px;
      margin-left: 5%;
  }
    
  @media (max-width: 768px) {
      width: 300px;
      height: 300px;
    border-radius: 15px;
    margin-left: 0;
      margin-right: 0;
    margin-top: 80px;
  }
`;

const MainContent = () => {
    const [isVisible, elementRef] = useIntersectionObserver({
    threshold: 0.1, // Adjust the threshold as needed
  });
    return (
  <MainContainer>
      <SideVideo autoPlay loop muted playsInline ref={elementRef} isVisible={isVisible}>
          <source src={sideVideo} type="video/mp4"/>
      </SideVideo>
      <TextContainer ref={elementRef} isVisible={isVisible}>
          <TextOverlay>
              By taking only relevant data and optimizing it in an intelligent way allows us to take a 'nano' amount of data and make it seem large.
    </TextOverlay>
          <AdditionalText>
              200x less data needed
          </AdditionalText>
    </TextContainer>
      <TextContainer ref={elementRef} isVisible={isVisible}>
          <TextOverlayAlt>
              We take a new approach focusing on the observability of the data used to train AI. Size is a critical factor. By significantly decreasing the amount of data needing governance, we can control the narrative and accuracy of the models we create.
    </TextOverlayAlt>
    </TextContainer>
  </MainContainer>
);};

export default MainContent;

